import "react-image-lightbox/style.css";
import "./src/css/style.css";
import "./src/css/style-added.css";
import "./src/css/icons.css";
import "./src/css/fonts/font-project/stylesheet.css";

// ES5 way
// exports.onClientEntry = () => {
// ES6 way
export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!("IntersectionObserver" in window)) {
    // eslint-disable-next-line no-unused-expressions
    import("intersection-observer");
  }
};

function isTouchDevice() {
  return (
    "ontouchstart" in window ||
    navigator.MaxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
}

document.body.classList.add(
  isTouchDevice() ? "touch-device" : "not-touch-device"
);
